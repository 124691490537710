/*
 * @Author: zhouxingtong
 * @Email: 495144492@qq.com
 * @Date: 2024-05-27 11:29:40
 * @LastEditors: zhouxingtong
 * @LastEditTime: 2024-11-21 11:51:31
 * @Description: 
 */
import storage from 'store'
import store from '@/store'
import {
  ACCESS_TOKEN,
  USER_INFO
} from '@/utils/request'
import {
  authLogin,
  getAuthUrl
} from '@/api/user'

/**
 * @param to 路由信息
 * @description 判断url中是否有authCode或者token 如果有则保存 如果没有则跳过
 * */
export default function saveToken(to) {
  return new Promise((resolve) => {
    let url = window.location.href
    let auth_code = url.split('auth_code=')[1]?.split('#/')[0]
    let token = url.split('token=')[1]?.split('#/')[0]

    if (token) {
      storage.clearAll()
      storage.set(ACCESS_TOKEN, token)
      store.commit('user/SET_TOKEN', token)

      //重定向到配置好的env变量 去掉url中的参数
      if (to.query && to.query.redirect) {
        window.location.href =
          `${process.env.VUE_APP_REDIRECT_URL}#` + to.query.redirect
      } else {
        window.location.href = process.env.VUE_APP_REDIRECT_URL
      }
      resolve()
    } else if (auth_code) {
      //通过authCode 登录
      authLogin({
        auth_code,
        login_type: 'json',
      }).then((res) => {
        if (res.code === 0) {
          storage.set(ACCESS_TOKEN, res.data.token)
          storage.set(USER_INFO, res.data.user)
          store.commit('user/SET_TOKEN', res.data.token)

          //重定向到配置好的env变量 去掉url中的参数
          window.location.replace(process.env.VUE_APP_REDIRECT_URL)

          resolve()
        }
      })
    } else if (window.android) {
      // 通过Android话机登录
      let jsonStr = window.android.getJsonStr()
      let jsonData = JSON.parse(jsonStr || '{}')

      if (jsonData && jsonData.token && jsonData.userInfo) {
        storage.set(ACCESS_TOKEN, jsonData.token)
        storage.set(USER_INFO, jsonData.userInfo)
        store.commit('user/SET_TOKEN', jsonData.token)

        resolve()
      } else {
        token = storage.get(ACCESS_TOKEN)

        resolve()
      }
    } else {
      token = storage.get(ACCESS_TOKEN)

      if (isWxBrowser() && !token) {
        getAuthUrl().then((res) => {
          if (res.code === 0) {
            window.location.replace(res.data.url)
          }
        })
      }
      resolve()
    }
  })
}

function isWxBrowser() {
  let ua = navigator.userAgent.toLowerCase()
  let isWx = ua.match(/MicroMessenger/i) == 'micromessenger'
  if (!isWx) {
    return false
  } else {
    return ua.match(/WxWork/i) == 'wxwork'
  }
}
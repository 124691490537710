import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './permission'
import './antDesignVue'
import '@/utils/tailwind.css'
import '@/utils/common.less'
import { VueAxios } from '@/utils/request'

// mount axios to `Vue.$http` and `this.$http`
Vue.use(VueAxios)
Vue.prototype.$EventBus = new Vue()
Vue.config.productionTip = false
window.addEventListener('load', function () {
  if (window.location.hash) {
    console.log(window.location.hash)
    window.location.replace(window.location.href.split('#')[0]);
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

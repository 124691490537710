/*
 * @Author: zhouxingtong
 * @Email: 495144492@qq.com
 * @Date: 2023-07-31 14:37:49
 * @LastEditors: zhouxingtong
 * @LastEditTime: 2023-10-24 20:24:15
 * @Description: 
 */
import storage from 'store'
import {
	getUserInfo,
	logout
} from '@/api/user'
import {
	ACCESS_TOKEN,
	USER_INFO
} from '@/utils/request'

const user = {
	namespaced: true,
	state: {
		token: '',
		name: '',
		welcome: '',
		avatar: '',
		roles: [],
		options: [],
		info: {
			user: {},
			shop: {}
		},
		infoChange: 0,
	},

	mutations: {
		SET_TOKEN: (state, token) => {
			state.token = token
		},
		SET_INFO: (state, info) => {
			state.info = info
		},
		SET_SHOP_OPTIONS: (state, options) => {
			state.options = options
		},
		SET_INFO_CHANGE: (state, infoChange) => {
			state.infoChange = infoChange
		},
	},

	actions: {
		// 获取用户信息
		GetInfo({
			commit
		}) {
			return new Promise((resolve) => {
				getUserInfo().then((res) => {
					if (res.code === 0) {
						storage.set(USER_INFO, res.data)
						commit('SET_INFO', res.data)
					}
					resolve()
				})
			})
		},
		// 登出
		Logout({
			commit,
			state
		}) {
			return new Promise((resolve) => {
				logout(state.token)
					.then(() => {
						commit('SET_TOKEN', '')
						commit('SET_INFO', '')
						storage.remove(ACCESS_TOKEN)
						storage.remove(USER_INFO)
						storage.remove('shopList')
						window.location.reload()
						resolve()
					})
					.catch((err) => {
						console.log('logout fail:', err)
						// resolve()
					})
					.finally(() => {})
			})
		},
		// 存储门店列表
		setShopOptions({
			commit
		}, data) {
			commit('SET_SHOP_OPTIONS', data)
			storage.set('shopList', data)
		},
		setInfoChange({
			commit
		}, data) {
			commit('SET_INFO_CHANGE', data)
		}
	},

	getters: {
		getUserInfo(state) {
			return state.info || {}
		},
	},
}

export default user
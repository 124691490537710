export default {
  namespaced: true,
  state: {
    contentLoading: false,
  },
  mutations: {
    SET_CONTENT_LOADING(state, payload) {
      state.contentLoading = payload
    },
  },
  actions: {
    startContentLoading({ commit }) {
      commit('SET_CONTENT_LOADING', true)
    },
    endContentLoading({ commit }) {
      commit('SET_CONTENT_LOADING', false)
    },
  },
}

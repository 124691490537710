import Vue from 'vue'
import {
  Button,
  Layout,
  Menu,
  Breadcrumb,
  Icon,
  Affix,
  Dropdown,
  Form,
  Input,
  FormModel,
  DatePicker,
  Radio,
  Checkbox,
  Switch,
  Select,
  TimePicker,
  ConfigProvider,
  Upload,
  Row,
  Col,
  Modal,
  message,
  notification,
  Spin,
  Divider,
  Table,
  Space,
  Badge,
  Tooltip,
  Descriptions,
  Tag,
  InputNumber,
  Alert,
  BackTop,
  TreeSelect,
  Tabs,
  Popconfirm,
  Anchor,
  Card
} from 'ant-design-vue'
// 引入iconfont 自定义图标组件
import iconFront from './assets/iconfont/iconfont.js'

Vue.use(Button)
  .use(Layout)
  .use(Menu)
  .use(Breadcrumb)
  .use(Icon)
  .use(Affix)
  .use(Dropdown)
  .use(Form)
  .use(Input)
  .use(FormModel)
  .use(DatePicker)
  .use(Radio)
  .use(Switch)
  .use(Checkbox)
  .use(Select)
  .use(TimePicker)
  .use(ConfigProvider)
  .use(Upload)
  .use(Modal)
  .use(Row)
  .use(Col)
  .use(Spin)
  .use(Table)
  .use(Divider)
  .use(Space)
  .use(Badge)
  .use(Descriptions)
  .use(Tooltip)
  .use(Alert)
  .use(Tag)
  .use(InputNumber)
  .use(BackTop)
  .use(Switch)
  .use(Tabs)
  .use(Popconfirm)
  .use(TreeSelect)
  .use(Anchor)
  .use(Card)

message.config({
  top: `100px`,
  maxCount: 3,
})

const myIcon = Icon.createFromIconfontCN({
  scriptUrl: iconFront,
})
// 引用
Vue.component('my-icon', myIcon)

Vue.prototype.$confirm = (config) =>
  Modal.confirm({
    width: '600px',
    icon: () =>
      new Vue().$createElement('a-icon', {
        props: { type: 'info-circle' },
        style: 'color:#e63e30',
      }),
    ...config,
  })
Vue.prototype.$message = message
Vue.prototype.$notification = notification
Vue.prototype.$info = Modal.info
Vue.prototype.$success = Modal.success
Vue.prototype.$error = Modal.error
Vue.prototype.$warning = Modal.warning

/**
 * 调用 wx.config
 *
 * @see https://open.work.weixin.qq.com/api/doc/90001/90144/90547
 */
export async function config(config, wx) {
  return new Promise((resolve, reject) => {
    console.info('wx.config', config)
    wx.config(config)
    wx.ready(resolve)
    wx.error(reject)
  }).then(
    () => {
      console.info('wx.ready')
    },
    (error) => {
      console.error('wx.error', error)
      throw error
    }
  )
}
/**
 * 调用 wx.agentConfig
 *
 * @see https://open.work.weixin.qq.com/api/doc/90001/90144/90548
 */
export async function agentConfig(config, wx) {
  return new Promise((success, fail) => {
    // console.info("wx.agentConfig", config);
    wx.agentConfig({ ...config, success, fail })
  })
  /*.then((res) => {
      console.info("wx.agentConfig success", res);
      return res;
    })
    .catch((err) => {
      console.error(err);
    });*/
}
